@use "sass:math";

$select-height: 35px;

// Default select (mobile)
.kr-field-wrapper.kr-select-wrapper.kr-real-select {
  position: relative;
  border-bottom: 1px solid $color-default;

  select {
    box-sizing: border-box;
    background-color: rgb(0, 0, 0, 0%);
    border: none;
    line-height: $select-height - 1px;
    padding-left: 4px;
    color: $color-dark;
    font-family: "Source Code Pro", sans-serif;
    font-size: 14px;
    width: $embedded-width;
  }

  span.kr-select-caret {
    display: block;
    position: absolute;
    top: math.div($select-height, 2) - 10px;
    right: 17px;

    svg {
      fill: $color-grey-font;
    }

    &.kr-read-only svg {
      fill: $color-grey-placeholder;
    }
  }

  span.kr-select-placeholder {
    opacity: 0;
    display: none;
  }
}

// Custom select (desktop)
.kr-field-wrapper.kr-select-wrapper.kr-custom-select {
  border-bottom: 1px solid $color-default;

  .kr-select {
    box-sizing: border-box;

    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);

    border: none;
    width: 100%;
    height: $select-height;
    padding-left: 4px;

    span.kr-selected-option {
      color: $color-dark;
      font-family: "Source Code Pro", sans-serif;
      font-size: 14px;
      opacity: 1;
      width: calc(100% - 25px);
    }

    span.kr-selected-option.kr-placeholder {
      color: $color-grey-placeholder;
      opacity: 0;
    }

    .kr-select-caret {
      box-sizing: border-box;

      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);

      right: 17px;
      top: 1px;
      height: $select-height - 1px;

      svg {
        fill: $color-grey-font;
      }

      &.kr-read-only svg {
        fill: $color-grey-placeholder;
      }
    }
  }

  .kr-options {
    width: 100%;
    left: 0;
    max-height: $select-height * 6;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10000;

    .kr-option {
      box-sizing: border-box;

      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);

      height: $select-height;
      padding-left: 4px;
      color: $color-default-label;
      font-family: "Source Code Pro", sans-serif;
      font-size: 14px;

      &:hover {
        background-color: #eee;
        color: $color-primary;
      }
    }

    &.top {
      &.kr-options-6 {
        top: -($select-height * 6) + 3px;
      }

      &.kr-options-5 {
        top: -($select-height * 5) + 3px;
      }

      &.kr-options-4 {
        top: -($select-height * 4) + 3px;
      }

      &.kr-options-3 {
        top: -($select-height * 3) + 3px;
      }

      &.kr-options-2 {
        top: -($select-height * 2) + 3px;
      }

      &.kr-options-1 {
        top: -($select-height * 1) + 3px;
      }
    }

    &.bottom {
      top: $select-height;
    }

    &:not(:hover) {
      .kr-option.kr-active-option {
        background-color: #eee;
        color: $color-primary;
      }
    }
  }
}

// Error
.kr-identity-document-type,
.kr-installment-number,
.kr-first-installment-delay {
  &.kr-error {
    .kr-field-wrapper.kr-select-wrapper.kr-custom-select,
    .kr-field-wrapper.kr-select-wrapper.kr-real-select {
      border-bottom: 1px solid $color-error;

      .kr-select-caret svg {
        fill: $color-error;
      }
    }

    .kr-label label {
      color: $color-error-label;
    }
  }
}
