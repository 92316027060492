/* Base libraries */
@import "package/material/variables";
@import "breakpoint_definition";
@import "https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap";

/* Mixins */
@import "./mixins/breakpoint";
@import "mixins/flex";
@import "desktop/mixin/clearfix";
@import "desktop/mixin/aspect_ratio";
@import "desktop/mixin/vertical_align";
@import "desktop/mixin/words";
@import "desktop/mixin/form_fields";
@import "desktop/mixin/placeholder";

/* Vendors */
@import "animations/base";
@import "animations/flippers/flipInX";

/* Embedded */
@import "package/material/index";

/* Popin */
@import "package/material-popin/index";
