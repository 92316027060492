@import "../no-theme/field";

// Initial state (without content)
.kr-field,
.kr-pan,
.kr-expiry,
.kr-security-code,
.kr-identity-document-number,
.kr-card-holder-name,
.kr-card-holder-mail,
.kr-identity-document-type,
.kr-installment-number,
.kr-first-installment-delay {
  box-sizing: content-box;
  width: 100%;
  height: 34px;
  position: relative;
  margin-bottom: 18px;
  border-bottom: 1px solid $color-default;
  display: block;
}

// Rendered state (with content)
&[krvue] {
  .kr-field,
  .kr-pan,
  .kr-expiry,
  .kr-security-code,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-identity-document-type,
  .kr-installment-number,
  .kr-first-installment-delay {
    border-bottom: 0;

    &.kr-focus .kr-field-component {
      border-bottom: 1px solid $color-primary;
    }

    &.kr-error .kr-field-component {
      border-bottom: 1px solid $color-error;
    }

    .kr-field-component {
      width: 100%;
      border-bottom: 1px solid $color-default;
    }

    .kr-field-component input.kr-input-field {
      box-sizing: border-box;
      border: 0;
      color: $color-dark;
      font-family: "Source Code Pro", sans-serif;
      font-size: 14px;
      outline: 0;
      padding-left: 4px;
      width: 100%;
      height: 100%;

      @include placeholder {
        color: $color-grey-placeholder;
        opacity: 1;
      }
    }
  }

  .kr-pan,
  .kr-expiry,
  .kr-security-code,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail {
    width: 100%;
    height: 35px;
  }

  // Non-iframe fields
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail {
    .kr-field-component input.kr-input-field {
      opacity: 0;
      transition: opacity 0.1s;
    }

    .kr-field-component.visible input.kr-input-field,
    .kr-field-component input.kr-input-field.visible {
      opacity: 1;
      transition: opacity 0.25s;
      transition-delay: 0.08s;
    }
  }

  // Custom
  .kr-field:not(
  .kr-pan, .kr-expiry, .kr-security-code, .kr-identity-document-number,
  .kr-card-holder-name, .kr-card-holder-mail, .kr-identity-document-type,
  .kr-installment-number, .kr-first-installment-delay, .kr-radio, .kr-checkbox) {
    height: 33px;
    border-bottom: 1px solid $color-default;

    &.kr-focus {
      border-bottom: 1px solid $color-primary;

      &.kr-error {
        border-bottom: 1px solid $color-error;
      }
    }
  }

  div[id^="kr-field-app-"] {
    width: 100%;
  }

  .kr-expiry.kr-size-half {
    margin-right: 10px;
  }
}

.kr-security-code {
  margin-bottom: 24px;
}

.kr-inner-wrapper.kr-error {
  .kr-field-container {
    input.kr-input-field {
      color: $color-error;
    }
  }
}

.kr-field-container {
  box-sizing: border-box;
  padding-right: 1px;
  display: block;

  input.kr-input-field {
    box-sizing: content-box;
    border: 0;
    color: $color-dark;
    font-family: "Source Code Pro", sans-serif;
    font-size: 14px;
    outline: 0;
    padding: 4px 0;
    width: 100%;
    line-height: normal;
    margin: 2px 0 0;

    @include placeholder {
      color: $color-default;
    }
  }
}
