// Labels
.kr-label {
  position: absolute;
  transition: all 0.25s;

  label {
    transition: font-size 0.25s;
    position: absolute;
    color: $color-default-label;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-family: "Source Code Pro", monospace;
    pointer-events: none;
    cursor: text;
  }

  &.default,
  &.kr-default {
    top: 12px;
    left: 5px;
    z-index: 0;

    label {
      color: $color-default-label;
      font-size: 0.88em;
    }
  }

  &.focus,
  &.kr-focus {
    top: -12px;
    left: 4px;
    z-index: 1;

    label {
      color: $color-primary;
      font-size: 0.75em;
    }
  }

  &.kr-focus.kr-default-custom {
    top: 0 !important;
  }

  &.kr-error {
    label {
      color: $color-error-label !important;
    }
  }
}

// Customer label specific styles
.kr-field .kr-label.default {
  top: 11px;
}

.kr-field.kr-field-wrapper .kr-label.kr-default.kr-default-custom {
  top: 0;
  left: 2px;
}

.kr-field.kr-field-wrapper {
  .kr-label.default,
  .kr-label.kr-default {
    top: 12px;

    &.kr-focus {
      top: -12px;
    }
  }
}
