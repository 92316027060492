@use "sass:math";
@import "../base/custom_checkbox";
@import "../base/custom_radio";

// Hosted fields themed customization
input.kr-theme {
  &[type="text"] {
    height: 29px;
  }

  &[type="checkbox"] {
    height: 50px;
  }
}

> input.kr-theme[type="text"] {
  box-sizing: content-box;
  position: relative;
  width: $embedded-width;
  height: 29px;
  border-bottom: 1px solid $color-default;
  margin-left: 10px;
  margin-top: 16px;
  margin-bottom: 18px;
  display: none;

  &.kr-focus {
    border-bottom: 1px solid $color-primary;

    &.kr-error {
      border-bottom: 1px solid $color-error;
    }
  }
}

// Text
.kr-field.kr-text {
  &.kr-field-wrapper.kr-visible {
    display: flex;
  }

  &.kr-visible.kr-error {
    border-bottom: 1px solid $color-error;
  }

  input.kr-theme {
    background-color: rgb(255, 255, 255, 0%);
    border: none;
    color: $color-dark;
    font-family: "Source Code Pro", monospace;
    font-size: 0.88em;
    height: initial;
    margin-top: 1px;
    outline: none;
    padding: 10px 4px;
    width: 100%;

    @include placeholder {
      font-family: "Source Code Pro", monospace;
    }
  }
}

// Checkbox
.kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper {
  width: 100%;

  .kr-field-control {
    height: auto;
    width: 100%;
    font-size: 0.8em;

    &.kr-checkbox-layout {
      display: block;
    }

    .kr-label {
      padding-left: 5px;
    }

    .kr-label.focus {
      padding-left: 6px;
    }
  }

  .kr-label {
    position: relative;
    display: block;
    top: 8px;

    label {
      font-size: 12.8px;
      color: #888;
      line-height: normal;
    }
  }
}

div[id^="kr-field-app-"] .kr-checkbox .kr-field-wrapper .kr-checkbox-input-wrapper .kr-field-control.kr-checkbox {
  padding-left: 30px;
}

// Default type
.kr-field.kr-checkbox.kr-checkbox-type-default {
  margin-bottom: 21px;
  padding-left: 0;
}

// Label
.kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper {
  .kr-field-control {
    font-family: "Source Code Pro", monospace;
    font-size: 0.8em;
  }
}

.kr-field.kr-checkbox.kr-checkbox-type-default .kr-checkbox-input-wrapper {
  // Unchecked
  .kr-checkbox-control-indicator {
    display: block;
    background-color: $color-light;
    border: 1px solid #bbb;
  }

  // Hover and focus
  .kr-field-control:hover input ~ .kr-checkbox-control-indicator,
  .kr-field-control input:focus ~ .kr-checkbox-control-indicator {
    background-color: $color-light;
  }
}

// Switch type
.kr-field.kr-checkbox.kr-checkbox-type-switch .kr-checkbox-input-wrapper .kr-field-control.kr-checkbox {
  // Label
  color: #777;

  // Default background
  input.kr-theme {
    margin-right: initial;
    background: $color-default;

    // Checked background
    &:checked {
      background: $color-primary;
    }

    // Button
    &::after {
      background: $color-light;
    }
  }
}

.kr-input-wrapper {
  opacity: math.pow(10, -5);
  width: 100%;
  transition: opacity 0.1s;

  &.visible {
    opacity: 1;
    transition: opacity 0.25s;
    transition-delay: 0.08s;
  }
}

.kr-field.kr-radio {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 0;

  label {
    outline: none;
    font-size: 0.88em;
    font-family: "Source Code Pro", monospace;
    color: #777;
  }
}
