$small-sides-margin: 20px;
$default-sides-margin: 25px;

// Positions
&.kr-help-button-inner-field {
  width: $embedded-width + $small-sides-margin * 2 + 45;
  padding-left: 20px;
  padding-right: 20px;

  div.kr-popin-modal-header {
    width: 100%;

    span.kr-popin-shop-name {
      padding:
        $header-height - 30px $small-sides-margin 10px
        $small-sides-margin;
      width: calc(100% - 40px);
    }
  }

  .kr-pan,
  .kr-expiry,
  .kr-security-code,
  .kr-field.kr-text,
  .kr-field.kr-checkbox {
    margin-left: 0;
  }

  .kr-payment-button,
  .kr-form-error {
    width: 100%;
    margin-left: 0;
  }

  .kr-popin-modal-footer {
    .kr-whitelabel-logo img {
      margin-right: $small-sides-margin;
    }
  }
}
