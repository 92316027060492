.kr-layer {
  border-radius: 5px;
  top: -15px;
  right: -5px;
  left: -5px;

  .kr-layer-message {
    font-family: "Source Code Pro", monospace;
    color: #fff;
    cursor: pointer;
  }
}
