@use "./variables" as *;

@mixin main() {
  .kr-currency-conversion {
    padding-top: 4px;

    span {
      color: $color-grey;
      font-family: "Source Code Pro", monospace;
      font-size: 12px;

      strong {
        color: $color-primary;
        font-family: "Source Code Pro", monospace;
        font-size: 12px;
      }
    }
  }
}
