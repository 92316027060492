@use "./variables" as *;
@use "./payment-animation";

@mixin button() {
  box-sizing: border-box;
  margin-bottom: 7px;
  text-align: center;
  width: 100%;
  border: 0;
  color: $color-light;
  background-color: $color-primary;
  font-size: 0.9em;
  outline: none !important;
  height: 40px;
  font-family: inherit;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.4s;
}

@mixin main() {
  .kr-payment-button,
  .kr-smart-form-modal-button {
    @include button;

    &.kr-loading {
      @include payment-animation.loading;
    }

    &:focus {
      background-color: $color-primary;
      color: $color-light;
      box-shadow: rgb(0, 0, 0, 16%) 0 3px 6px, rgb(0, 0, 0, 23%) 0 3px 6px;
    }

    & > span {
      user-select: none;
      transition: opacity 0.3s;
      transition-delay: 0.4s;
      opacity: 1;
      display: inline-block;
      width: 100%;
      padding: 9px 0;
      line-height: 22px;
      color: inherit;
      text-align: center;
      font-family: "Source Code Pro", monospace;
      font-size: 14.4px;
      cursor: pointer;

      &.not-visible {
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0s;
      }
    }

    &:disabled span {
      cursor: not-allowed;
    }
  }
}
