.kr-pan,
.kr-expiry,
.kr-security-code,
.kr-field.kr-text,
.kr-field.kr-checkbox {
  width: 100%;
}

.kr-pan,
.kr-expiry,
.kr-security-code,
.kr-identity-document-number,
.kr-card-holder-name,
.kr-card-holder-mail,
.kr-identity-document-type,
.kr-installment-number,
.kr-first-installment-delay {
  margin: 10px 0;
}

.kr-field.kr-text {
  margin: 10px $sides-margin 10px 0;
}

.kr-field.kr-checkbox {
  margin: 10px $sides-margin 10px 0;
}

/* Expiry and CVV side by side */

/*
.kr-pan {
    margin: 20px $sides-margin 12px $sides-margin;
}

.kr-expiry {
    margin-left: $sides-margin;
    width: 105px;

    .kr-label label {
        width: 105px;
    }
}

.kr-security-code {
    width: 130px;

    .kr-label label {
        width: 130px;
    }
}

.kr-expiry,
.kr-security-code {
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 18px;
}

&.kr-locked-securityCode .kr-expiry {
  width: 230px;
}
*/
