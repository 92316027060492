.kr-visibility-button {
  display: flex;
  border-bottom: 1px solid $color-default;
}

.kr-focus .kr-visibility-button {
  border-bottom: 1px solid $color-primary;
}

.kr-error .kr-visibility-button {
  border-bottom: 1px solid $color-error;
}
