.kr-form-error {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  border: 0;
  margin-bottom: 20px;
  display: none;

  span {
    user-select: none;
    box-sizing: border-box;
    color: $color-error;
    padding: 4px 2px;
    display: inline;
    font-size: 0.9em;
    line-height: 24px;
    vertical-align: middle;
    font-family: "Source Code Pro", monospace;
    cursor: default;
  }

  span.kr-extra-content {
    padding: 0;
    display: inline-block;

    i.kr-error-icon {
      display: block;
      width: 22px;
      height: 21px;
      background-image: url("../logos/error-material.svg");
      background-repeat: no-repeat;
      background-size: 22px;
    }
  }
}

.kr-form-error-visible {
  display: block;
}
