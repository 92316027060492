/* VARIABLES */
@import "./variables";

/* Components */
@import "./button";

.kr-embedded[kr-popin] {
  // Flex direction
  @include flex-direction(column);

  margin-top: 0;
  margin-left: 0;
  display: none;
  box-shadow: 0 0 15px #121212;
  transition: height 1s;
  width: $form-width + $sides-margin;
  padding-top: $header-height + 20px;
  padding-left: $sides-margin;

  @import "./header";
  @import "./field";
  @import "./error";
  @import "./payment_button";
  @import "./footer";
  @import "./help-button";
  @import "./wallet_card_list";
  @import "./wallet_tabs";
  @import "./layer";
}
