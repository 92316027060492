@use "./payment-button";
@use "./payment-animation";
@import "./variables";

@mixin main() {
  .kr-simple-modal-wrapper .kr-simple-modal {
    @include payment-animation.main;

    max-width: 380px;
    box-shadow:
      rgb(0, 0, 0, 30%) 0 19px 38px,
      rgb(0, 0, 0, 22%) 0 15px 12px;

    @media (max-width: 420px) {
      margin: 0 10px;
    }

    .kr-simple-modal-header {
      padding: 10px;

      .kr-brand-icon,
      .kr-brand-icon svg {
        width: 30px;
        height: 24px;
      }

      .kr-simple-modal-header-title {
        font-family: "Source Code Pro", monospace;
        color: #333;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
      }
    }

    .kr-simple-modal-content {
      .kr-simple-modal-content-main p {
        font-family: "Source Code Pro", monospace;
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 6px 10px;

        strong {
          font-family: "Source Code Pro", monospace;
          color: #333;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .kr-simple-modal-content-optional {
        margin: 10px 10px 0;
        padding: 15px;
        background-color: #f4f4f4;
        border: 1px solid #ddd;

        .kr-simple-modal-content-optional-item {
          span,
          a,
          strong {
            font-family: "Source Code Pro", monospace;
            color: #333;
            font-size: 12px;
          }

          a {
            color: $color-primary;
            font-weight: 500;
          }
        }
      }
    }

    .kr-simple-modal-footer {
      padding-top: 20px;

      button {
        @include payment-button.button;
      }

      button,
      button span {
        color: #fff;
        font-family: "Source Code Pro", monospace;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        visibility: visible;
        cursor: pointer;
      }

      button {
        width: auto;
        padding: 8px 16px;
        margin: 0;
        background-color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.kr-btn-error {
          background-color: $color-error;
        }

        &.kr-btn-secondary {
          background-color: transparent;
          color: $color-grey-font;

          span {
            color: $color-grey-font;
          }
        }

        .kr-spinner {
          .kr-icon {
            margin-top: 55px;
          }

          .kr-icon svg circle {
            stroke: #fff;
          }
        }

        // Loading state
        &.kr-loading {
          @include payment-animation.loading;
        }
      }

      &--vertical {
        flex-direction: column;
        gap: 2px;
        padding-top: 0;
        align-items: center;

        button {
          width: fit-content;
        }
      }
    }
  }
}
