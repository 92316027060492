@use "sass:math";

$color-primary: var(--kr-global-color-primary);
$color-primary-light: #7986cb;
$color-default: #bbb;
$color-default-label: #aaa;
$color-error: #e25950;
$color-error-label: #ffa27b;
$color-dark: #111;
$color-light: #fff;
$color-grey: #464a4c;
$color-accent: #e91e63;
$color-grey-font: #717171;
$color-disabled: #ebebe4;
$color-grey-placeholder: #999;
$color-icon-error: #d9534f;
$color-error: #d9534f;
$circle-size: 30px;
$check-height: math.div($circle-size, 2);
$check-width: math.div($check-height, 2);
$check-left: math.div($circle-size, 6);
$check-thickness: math.div($circle-size, 10);
$embedded-width: 245px;
$icon-width: 40px;
$help-width: 50px;
$form-width: $embedded-width + $help-width;
$inner-width: $embedded-width + $help-width - 5px;
