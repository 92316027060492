@use "../material/payment-animation";

.kr-popin-utils button.kr-popin-button {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  width: $embedded-width;
  border: 0;
  color: $color-light;
  background-color: $color-primary;
  font-size: 0.9em;
  outline: none !important;
  height: 40px;
  font-family: "Source Code Pro", monospace;
  border-radius: 4px;
  cursor: pointer;

  @include payment-animation.main;

  // Adjust spinner vertical centering inside popin button
  .kr-spinner {
    margin-top: 0;
    top: 0;

    .kr-icon {
      margin-top: 0;
      height: 100%;
      position: relative;
      top: calc(50% - 12px);
    }
  }

  // Swap label & spinner visibility when loading
  &.kr-loading {
    @include payment-animation.loading;
  }
}
