.kr-field-wrapper .kr-icon-wrapper-root {
  position: relative;
}

.kr-pan,
.kr-expiry,
.kr-security-code,
.kr-identity-document-number,
.kr-card-holder-name,
.kr-card-holder-mail {
  .kr-field-wrapper .kr-icon-wrapper-root {
    border-bottom: 1px solid $color-default;
  }

  &.kr-focus .kr-field-wrapper .kr-icon-wrapper-root {
    border-bottom: 1px solid $color-primary;
  }

  &.kr-error .kr-field-wrapper .kr-icon-wrapper-root {
    border-bottom: 1px solid $color-error;
  }

  .kr-field-wrapper .kr-icon-wrapper-root,
  .kr-field-wrapper .kr-icon {
    width: $icon-width;
    height: 34px;
  }

  .kr-field-wrapper .kr-icon,
  .kr-field-wrapper .kr-icon .kr-icon-wrapper {
    display: block;
  }
}

.kr-icon-wrapper,
.kr-icon-inner-wrapper,
.kr-icon-wrapper-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.kr-icon-wrapper svg {
  width: 28px;
  height: 20px;
}
