@use "./discount-panel";
@use "./payment-animation";
@use "./currency-conversion";
@use "./root-variables";
@use "./payment-button";

/* Brand buttons */
@import "./brand-button";
@import "./error_alert";

/* Discount Panel */
@include discount-panel.root;
@import "./layer_manager";

.kr-embedded {
  @import "./custom_field";
}

.kr-embedded {
  /* Components */
  @include payment-animation.main;
  @include payment-button.main;
  @import "./brand-selector";
  @import "./field";
  @import "./icon";
  @import "./error";
  @import "./label";
  @import "./wrappers";
  @import "./single-line";
  @import "./help-button";
  @import "./delete-button";
  @import "./select";
  @import "./text-field";
  @import "./disabled";
  @import "./wallet_tabs";
  @import "./wallet_card_list";
  @import "./visibility_button";
  @include currency-conversion.main;

  /* Font */
  font-family: "Source Code Pro", monospace;
  margin-top: 16px;
  width: $inner-width;

  &.kr-ios-9 {
    @import "./ios9";
  }

  &.kr-ios,
  &.kr-macos {
    @import "./ios";
  }

  @include discount-panel.embedded;
}
