@import "initial";

.kr-embedded,
.kr-checkout,
.kr-discount-panel,
.kr-layer-manager {
  @include initial;
  @import "normalize";
}

@import "package.no-theme";
@import "package.material";
