// Fixing for iOS 9

&[krvue] {
  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  & > .kr-field-wrapper {
    .kr-field-component {
      width: $embedded-width - $icon-width;
    }
  }

  .kr-expiry,
  .kr-security-code {
    .kr-field-component {
      width: $embedded-width;
    }
  }

  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox,
  .kr-expiry,
  .kr-security-code,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  & > .kr-field-wrapper {
    .kr-field-component .kr-iframe-wrapper iframe {
      height: 34px !important;
    }
  }
}
