.kr-pan .kr-field-wrapper .kr-icon .kr-icon-wrapper {
  display: inline-block;
  z-index: 5;
}

.kr-icon-inner-wrapper {
  position: relative;

  .kr-icon-wrapper-layer {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
}

.kr-icon-arrow {
  height: 100%;
  position: relative;
  display: flex;
  top: 0;
  flex-direction: column;
  justify-content: center;
  right: 34px;
  transform: rotate(0deg);
  transition: transform 0.1s ease-in;
  z-index: 3;
  cursor: pointer;

  svg {
    height: auto;
    width: 10px;

    path {
      fill: rgb(113, 113, 113);
    }
  }
}

.kr-icon-arrow-turned {
  display: none;
}

.kr-brand-selector {
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0, 0, 0, 8%), 0 2px 4px rgb(0, 0, 0, 11%);
  opacity: 0;
  display: none;
  padding: 8px;
  position: absolute;
  right: 0;
  top: -21px;
  z-index: 10;
  margin-top: 10px;
  border: 1px solid #ddd;
  transition: all 0.2s ease-in-out;
  zoom: 0;

  .kr-brand-selector-wrapper {
    position: relative;
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  li {
    background-color: #fff;
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: block;
    margin-top: 5px;
    cursor: pointer;

    svg {
      border: 4px solid rgb(0, 0, 0, 0%);
      opacity: 0.4;
      margin-bottom: -4px;
      cursor: pointer;
    }
  }

  li.kr-selected-brand {
    svg {
      opacity: 1;
    }
  }

  svg {
    width: 34px;
    height: 22px;
  }
}

.kr-open {
  .kr-brand-selector {
    opacity: 1;
    zoom: 1;
    display: block;
  }
}
