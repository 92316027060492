.kr-delete-button-wrapper {
  cursor: pointer;
  display: block;
  position: relative;
  border-bottom: 1px solid $color-default;
  height: 34px;
  padding: 0 8px;

  &:not(.kr-visible) {
    display: none;
  }

  &.kr-card-list {
    border-bottom: 0;
  }

  .kr-delete-button {
    display: block;
    height: 100%;

    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(row);

    .kr-delete-icon {
      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);

      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;

        path {
          &.icon {
            fill: $color-grey-font;
          }

          &.background {
            fill: $color-light;
          }
        }
      }

      &.kr-error svg path {
        &.icon {
          fill: $color-icon-error;
        }

        &.background {
          fill: $color-light;
        }
      }
    }
  }

  &.kr-card-list {
    height: 18px;
    padding: 0 12px 0 0;
  }
}

.kr-error .kr-delete-button-wrapper:not(.kr-card-list) {
  border-bottom: 1px solid $color-icon-error;
}

.kr-focus:not(.kr-error) .kr-delete-button-wrapper:not(.kr-card-list) {
  border-bottom: 1px solid $color-primary;
}
