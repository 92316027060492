// Disabled state
.kr-inner-wrapper.kr-disabled input.kr-input-field,
.kr-field-element.kr-disabled .kr-field-component input.kr-input-field[disabled] {
  color: $color-default;
}

&.kr-ios .kr-inner-wrapper.kr-disabled input.kr-input-field,
&.kr-safari .kr-inner-wrapper.kr-disabled input.kr-input-field {
  color: $color-grey;
}
