div.kr-popin-modal-header {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);

  border-bottom: 1px solid #808080;
  height: $header-height;
  margin-bottom: 30px;
  width: $embedded-width + $sides-margin * 2;

  &.kr-secure-iframe-visible {
    width: 100%;
    height: 30px;
    border-bottom: 0;
    margin-bottom: 0;
    background: #e4e4e4;

    i {
      &.fi-x-circle.icon-close-popup {
        margin-top: 0;
      }

      &.fi-lock {
        margin-left: 5px;
      }
    }
  }

  span.kr-popin-shop-name {
    @include flexbox;
    @include align-items(flex-end);

    bottom: 0;
    font-family: "Source Code Pro", monospace;
    font-weight: normal;
    padding: $header-height - 30px $sides-margin 10px $sides-margin;
    position: absolute;
    width: calc(100% - 100px);
    margin-top: 0;
    margin-bottom: 0;

    span {
      font-family: "Source Code Pro", monospace;
      font-weight: normal;
      line-height: 20px;
    }
  }

  i.fi-x-circle.icon-close-popup {
    width: 12px;
    height: 12px;
    top: 0;
    right: 0;
    margin: 5px 5px 0 0;
  }

  // Long shop name
  &.kr-long-shop-name {
    .kr-popin-modal-header-background-image {
      display: none;
    }

    span.kr-popin-shop-name span {
      text-align: center;
    }
  }
}
