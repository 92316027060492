/* Modal */
.kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal {
  background-color: #fff;
  box-shadow: 0 0 15px #121212;

  .kr-help-modal-header {
    background-color: $color-primary;

    .kr-help-modal-close-button svg {
      border-radius: 50%;
      background-color: $color-primary !important;
    }

    .kr-help-modal-close-button svg path:first-child {
      fill: $color-primary !important;
    }

    .kr-help-modal-close-button svg path:last-child {
      fill: $color-light !important;
    }
  }

  .kr-help-modal-content {
    margin-top: 20px;
  }

  .kr-help-modal-content > div.kr-help-content {
    font-family: "Source Code Pro", monospace;
    font-size: 12px;
    color: #333;
  }

  .kr-help-modal-content > .kr-help-image,
  .kr-help-image-amex {
    margin-top: 25px;
  }
}
