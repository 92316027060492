// Payment animation
@import "./variables";

@mixin loading() {
  span {
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0s;
  }

  .kr-spinner {
    opacity: 1;
  }
}

@mixin main() {
  .kr-spinner {
    box-sizing: border-box;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    display: block;
    width: 100%;
    height: 40px;
    opacity: 0;
    z-index: 1;
    margin-top: -93px;
    pointer-events: none;

    .kr-icon {
      transition: margin-left 0.05s;
      text-align: center;
      margin-top: 61px;
      display: block;
      cursor: pointer;

      .kr-border {
        transition: stroke-dashoffset 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        stroke: $color-default;
        stroke-dasharray: 203;
        stroke-dashoffset: 62.75;
        transform-origin: 50% 50%;
        animation: kr-spin 0.9s linear infinite;
      }

      .kr-checkmark {
        transition: stroke-dashoffset 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
        stroke: $color-light;
        stroke-dasharray: 60;
        stroke-dashoffset: 60;
      }

      .kr-label {
        display: none;
        position: relative;
      }

      @keyframes kr-spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(1turn);
        }
      }

      @keyframes kr-spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(1turn);
        }
      }
    }

    .kr-icon.kr-success {
      margin-left: -165px;

      .kr-border {
        stroke-dashoffset: 0 !important;
      }

      .kr-checkmark {
        stroke-dashoffset: 0 !important;
      }

      .kr-label {
        color: $color-light;
        display: block;
        margin-top: 77px;
        margin-left: 50px;
        font-size: 0.8em;
        user-select: none;
      }
    }

    @include -frozen;
  }
}

@mixin -frozen() {
  &.kr-button-animation--frozen {
    .kr-icon {
      .kr-border {
        animation: none;
      }
    }
  }
}
