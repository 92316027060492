.kr-wallet-tabs {
  margin-bottom: 20px;

  .kr-wallet-tab {
    padding: 5px 10px;
    margin: 0 4px;
    border-bottom: 2px solid rgb(0, 0, 0, 0%);
    cursor: pointer;

    &.kr-selected {
      background-color: rgb(0, 0, 0, 0%);
      border-bottom: 2px solid $color-primary;
    }

    span {
      font-family: "Source Code Pro", monospace;
      font-size: 13px;
      color: $color-primary;
      cursor: pointer;
    }

    &.kr-disabled span {
      color: $color-grey-font;
    }
  }
}
