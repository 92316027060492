.kr-identity-document-number.kr-error,
.kr-card-holder-name.kr-error,
.kr-card-holder-mail.kr-error {
  // Error
  .kr-field-wrapper.kr-text-wrapper {
    .kr-label label {
      color: $color-error-label;
    }

    .kr-field-component {
      border-bottom: 1px solid $color-error;
    }
  }
}
