@use "sass:math";

.kr-iframe-wrapper {
  opacity: math.pow(10, -5);
  transition: opacity 0.1s;

  &.visible {
    opacity: 1;
    transition: opacity 0.25s;
    transition-delay: 0.08s;
  }
}

// Form wrapper
.kr-outer-wrapper {
  box-sizing: border-box;
  display: block;
  width: $embedded-width - $icon-width;

  .kr-inner-wrapper {
    box-sizing: border-box;
    display: block;
    padding: 4px;
    zoom: 1;
  }
}
